import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import CancelingModal from '../components/canceling/CancelingModal';
import LessonAppointmentCards from './components/LessonAppointmentCards/LessonAppointmentCards';
import ReschedulingSessionsModal from '../components/rescheduling/ReschedulingSessionsModal';
import AutomaticBookingsToggle from '../../automatic-bookings-toggle';

import { getSessionsStatus } from '../../../actions/sessions';
import { cleanPreviousState, setBulkingState, cleanSuccess } from '../../../actions/new-booking';
import { setSelectedTutor } from '../../../actions/selected-tutor';
import { selecAppointmentsToBeCanceledOrRescheduled } from '../../../reducers/new-booking';
import { updateAutomaticBookings } from '../../../actions/user';

import styles from './MyBookings.module.scss';

import { initChangeCoachProcess, setChangeCoachSuccess } from '../../../actions/change-coach';
import SuccessToast from '../../design-system/toast/successToast/SuccessToast';
import MyBookingsThreeDots from './components/MyBookingThreeDots/MyBookingsThreeDots';
import PastAppointmentCards from './components/PastAppointmentCards/PastAppointmentCards';
// import CompletenessSnackbar from '../../design-system/completeness-snackbar/CompletenessSnackbar';
import { getOnboardingData } from '../../../actions/new-onboarding';
import { sendSentryError } from '../../../setup/sentry';

const MyBookings = ({
  isBulkingState,
  isGettingCourseDetails,
  cleanPreviousBookingStateAction,
  cleanSuccessAction,
  initChangeCoachProcessAction,
  conversationClassesWithBookingStatus,
  courseId,
  getSessionsStatusAction,
  getOnboardingDataAction,
  isCancelingAppointmentSuccess,
  isChangeCoachSuccess,
  isCreatingAppointmentSuccess,
  isLoadingTuitionLessonsStatus,
  // isFirstBooking,
  isReschedulingAppointmentSuccess,
  tuitionLessonsWithBookingStatus,
  shouldOpenCancelingModal,
  shouldOpenReschedulingModal,
  selectedAction,
  setChangeCoachSuccessAction,
  setSelectedTutorAction,
  token,
  user,
  updateAutomaticBookingsAction
}) => {
  const appointmentsToBeCanceledOrRescheduled = useSelector((state) => selecAppointmentsToBeCanceledOrRescheduled(state, selectedAction)) || [];
  const isFeatureEnabled = useFeatureIsOn('automatic-bookings') || useFeatureIsOn('automatic-bookings-default');

  useEffect(() => {
    initChangeCoachProcessAction(false);
    cleanPreviousBookingStateAction();
    getOnboardingDataAction({ token });
  }, []);

  useEffect(() => {
    if (courseId) getSessionsStatusAction(token, courseId);
  }, [courseId]);

  const getTutorFromLastAppointment = (lessonsWithStatus) => {
    if (lessonsWithStatus?.booked.length) {
      const lastTutor = lessonsWithStatus.booked[lessonsWithStatus.booked.length - 1].appointment_object.tutor;
      setSelectedTutorAction({ ...lastTutor, name: lastTutor.first_name });
    } else {
      if (lessonsWithStatus?.happened.length) {
        const lastTutor = lessonsWithStatus.happened[lessonsWithStatus.happened.length - 1].appointment_object.tutor;
        setSelectedTutorAction({ ...lastTutor, name: lastTutor.first_name });
      }
    }
  };

  useEffect(() => {
    getTutorFromLastAppointment(tuitionLessonsWithBookingStatus);
  }, [tuitionLessonsWithBookingStatus]);

  const handleToggleAutomaticBookings = async (isEnabled) => {
    try {
      await updateAutomaticBookingsAction(token, isEnabled);
    } catch (error) {
      sendSentryError({ error, context: 'handleToggleAutomaticBookings' });
      console.error('Error updating automatic bookings preference:', error);
    }
  };

  return (
    <div className={styles.myBookingsContainer} data-testid="my-bookings">
      <div className={styles.myBookingsHeader}>
        <div className={styles.titleContainer}>
          <h2 className={[styles.title, isBulkingState && styles.titleWithBulkingstageOpen].join(' ')}>
            {isBulkingState ? `Select lesson(s) to manage: ${appointmentsToBeCanceledOrRescheduled.length} selected` : 'My bookings'}
          </h2>
          {isFeatureEnabled && user && user.automatic_bookings_asked !== undefined && (
            <AutomaticBookingsToggle isEnabled={!!user.automatic_bookings} onChange={handleToggleAutomaticBookings} />
          )}
        </div>
        <MyBookingsThreeDots appointmentsToBeCanceledOrRescheduled={appointmentsToBeCanceledOrRescheduled} />
      </div>
      <LessonAppointmentCards
        conversationBookings={conversationClassesWithBookingStatus.booked}
        isLoading={isLoadingTuitionLessonsStatus || isGettingCourseDetails}
        tuitionBookings={tuitionLessonsWithBookingStatus.booked}
      />
      <div className={styles.pastCardsSection}>
        <h2 className={styles.sectionTitle}>Past Live Practices</h2>
        <PastAppointmentCards
          conversationBookings={conversationClassesWithBookingStatus.happened}
          isLoading={isLoadingTuitionLessonsStatus || isGettingCourseDetails}
          tuitionBookings={tuitionLessonsWithBookingStatus.happened}
        />
      </div>
      {shouldOpenReschedulingModal && <ReschedulingSessionsModal />}
      {shouldOpenCancelingModal && <CancelingModal />}
      <SuccessToast
        msg="All booked! You should receive an email shortly with your booking confirmation and further details."
        onClose={cleanSuccessAction}
        show={!isChangeCoachSuccess && isCreatingAppointmentSuccess}
        testId="live-session-booking-success"
      />
      <SuccessToast
        msg="All rescheduled! You should receive an email shortly with your rescheduling confirmation and further details."
        onClose={cleanSuccessAction}
        show={isReschedulingAppointmentSuccess}
        testId="live-session-rescheduling-success"
      />
      <SuccessToast
        msg="Cancelled! You should receive an email shortly with your cancellation confirmation and further details."
        onClose={cleanSuccessAction}
        show={!isChangeCoachSuccess && isCancelingAppointmentSuccess}
        testId="live-session-cancelling-success"
      />
      <SuccessToast
        msg="You successfully changed your coach!"
        onClose={() => {
          setChangeCoachSuccessAction(false);
          cleanSuccessAction();
        }}
        show={isChangeCoachSuccess}
        testId="live-session-change-coach-success"
      />
      <SuccessToast
        msg={user?.automatic_bookings ? 'Automatic bookings enabled' : 'Automatic bookings disabled'}
        onClose={cleanSuccessAction}
        show={user?.automatic_bookings_asked && isFeatureEnabled}
        testId="automatic-bookings-toggle-success"
      />
      {/* <CompletenessSnackbar
        show={isFirstBooking}
        onShow={() => mixpanel.showedSnackbar('First Booking')}
        onClose={() => mixpanel.closedSnackbar('First Booking')}
        onShare={() => mixpanel.click('Share on LinkedIn')}
      /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isBulkingState: state.newBooking.generic.isBulkingState,
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  conversationClassesWithBookingStatus: state.sessions.conversationSessions.currentLessonsStatus,
  courseId: state.courses?.selectedCourseDetails?.courseDetails?.id,
  isCancelingAppointmentSuccess: state.newBooking.canceling.isCancelingAppointmentSuccess,
  isChangeCoachSuccess: state.changeCoach.isChangeCoachSuccess,
  isLoadingConversationClassesStatus: state.sessions.conversationSessions.isLoadingLessonsStatus,
  isLoadingTuitionLessonsStatus: state.sessions.tuitionSessions.isLoadingLessonsStatus,
  tuitionLessonsWithBookingStatus: state.sessions.tuitionSessions.currentLessonsStatus,
  isCreatingAppointmentSuccess: state.newBooking.bookingLessons.isCreatingAppointmentSuccess,
  // isFirstBooking: state.newBooking.bookingLessons.isFirstBooking,
  isReschedulingAppointmentSuccess: state.newBooking.rescheduling.isReschedulingAppointmentSuccess,
  openBookingModal: state.newBooking.bookingLessons.openBookingModal,
  shouldOpenCancelingModal: state.newBooking.canceling.shouldOpenCancelingModal,
  shouldOpenReschedulingModal: state.newBooking.rescheduling.shouldOpenReschedulingModal,
  selectedAction: state.newBooking.generic.selectedAction,
  token: state.user.token,
  user: state.user
});

MyBookings.propTypes = {
  isBulkingState: PropTypes.bool.isRequired,
  isGettingCourseDetails: PropTypes.bool.isRequired,
  cleanPreviousBookingStateAction: PropTypes.func.isRequired,
  cleanSuccessAction: PropTypes.func.isRequired,
  initChangeCoachProcessAction: PropTypes.func.isRequired,
  conversationClassesWithBookingStatus: PropTypes.object.isRequired,
  courseId: PropTypes.number,
  getSessionsStatusAction: PropTypes.func.isRequired,
  getOnboardingDataAction: PropTypes.func.isRequired,
  getOrderInfoAction: PropTypes.func.isRequired,
  isCancelingAppointmentSuccess: PropTypes.bool.isRequired,
  isChangeCoachSuccess: PropTypes.bool.isRequired,
  isCreatingAppointmentSuccess: PropTypes.bool.isRequired,
  isLoadingTuitionLessonsStatus: PropTypes.bool.isRequired,
  // isFirstBooking: PropTypes.bool.isRequired,
  isReschedulingAppointmentSuccess: PropTypes.bool.isRequired,
  tuitionLessonsWithBookingStatus: PropTypes.object.isRequired,
  openBookingModal: PropTypes.bool.isRequired,
  shouldOpenCancelingModal: PropTypes.bool.isRequired,
  shouldOpenReschedulingModal: PropTypes.bool.isRequired,
  selectedAction: PropTypes.string,
  setBulkingStateAction: PropTypes.func.isRequired,
  setChangeCoachSuccessAction: PropTypes.func.isRequired,
  setSelectedTutorAction: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  user: PropTypes.object,
  updateAutomaticBookingsAction: PropTypes.func.isRequired
};

MyBookings.defaultProps = {
  getOrderInfoAction: () => {},
  isCancelingAppointmentSuccess: false,
  isCreatingAppointmentSuccess: false,
  isGettingCourseDetails: false,
  isReschedulingAppointmentSuccess: false,
  openBookingModal: false,
  shouldOpenCancelingModal: false,
  shouldOpenReschedulingModal: false
};

export default connect(mapStateToProps, {
  cleanPreviousBookingStateAction: cleanPreviousState,
  cleanSuccessAction: cleanSuccess,
  initChangeCoachProcessAction: initChangeCoachProcess,
  getSessionsStatusAction: getSessionsStatus,
  getOnboardingDataAction: getOnboardingData,
  setBulkingStateAction: setBulkingState,
  setChangeCoachSuccessAction: setChangeCoachSuccess,
  setSelectedTutorAction: setSelectedTutor,
  updateAutomaticBookingsAction: updateAutomaticBookings
})(MyBookings);
